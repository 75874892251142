<template>
  <div>
    <!-- MOBILE -->
    <div v-if="isMobileWidth === 1" class="mb-5 pb-10" style="height:100%">
      <div v-if="reload" class="row pl-3 pr-3 d-flex justify-space-between pt-3">
        <v-card
          width="47%"
          height="270px"
          class="round mb-3"
          elevation="0"
          outlined
          v-for="n in 6"
          :key="n+'A'"
        >
        <v-skeleton-loader
          type="card"
        ></v-skeleton-loader>
        </v-card>
      </div>
      <div v-else>
        <div v-if="isLoading" class="row pl-3 pr-3 d-flex justify-space-between pt-3">
          <v-card
            width="47%"
            height="270px"
            class="round mb-3"
            elevation="0"
            outlined
            v-for="n in 6"
            :key="n+'A'"
          >
          <v-skeleton-loader
            type="card"
          ></v-skeleton-loader>
          </v-card>
        </div>
        <div v-else>
          <!-- KETIKA LOGIN -->
          <div v-if="isLogin" class="row pl-3 pr-3 d-flex justify-space-between pt-3">
            <v-card
              width="47%"
              height="270px"
              class="round mb-3"
              elevation="0"
              outlined
              v-for="list in listInfluencers"
              :key="list.id"
            >
              <!-- <v-img class="pointer" @click="goToDetail(list.id)" height="150" :src="list.photo" /> -->
              <v-img
                class="pointer" @click="goToDetail(list.id)" height="150"
                :src="list.photo"
              >
                <v-app-bar
                  flat
                  color="rgba(0, 0, 0, 0)"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="list.isPriority === 1"
                    elevation="0"
                    color="white"
                    fab
                    x-small
                    class="recommended"
                    aria-label="recomended"
                  >
                    <v-icon class="primary--text">
                      {{ icons.mdiLightningBolt }}
                    </v-icon>
                  </v-btn>
                </v-app-bar>
              </v-img>

              <v-card-text class="pa-2 pb-0">
                <p v-if="list.name.length < 14" class="left-text black--text text-14 font-weight-bold mb-1">{{ list.name }}</p>
                <p v-else class="left-text black--text text-14 font-weight-bold mb-1">{{list.name.substring(0,14)+".."}}</p>
                <!-- TIKTOK -->
                <div v-if="type === 'tiktok'">
                  <v-btn @click="book(list.tiktok)" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-shopping-cart mr-2"></i>Book Now</v-btn>
                  <p v-if="list.tiktok.length < 20" class="mb-0 text-12"><i class="fab fa-tiktok pr-1"></i>{{ list.tiktok }}</p>
                  <p v-else class="mb-0 text-12"><i class="fab fa-tiktok pr-1"></i>{{list.tiktok.substring(0,20)+".."}}</p>
                  <p class="text-12 mb-0"><i class="far fa-user-circle pr-1"></i>{{list.follower_tiktok | nFormatter}} Followers</p>
                </div>
                <!-- INSTAGRAM -->
                <div v-else>
                  <v-btn @click="book(list.instagram)" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-shopping-cart mr-2"></i>Book Now</v-btn>
                  <p v-if="list.instagram.length < 20" class="mb-0 text-12"><i class="fab fa-instagram pr-1"></i>{{ list.instagram }}</p>
                  <p v-else class="mb-0 text-12"><i class="fab fa-instagram pr-1"></i>{{list.instagram.substring(0,20)+".."}}</p>
                  <p class="text-12 mb-0"><i class="far fa-user-circle pr-1"></i>{{list.follower_ig | nFormatter}} Followers</p>
                </div>
              </v-card-text>
            </v-card>
          </div>
          <!-- KETIKA BELUM LOGIN -->
          <div v-else class="row pl-3 pr-3 d-flex justify-space-between pt-3">
            <v-card
              width="47%"
              height="270px"
              class="round mb-3"
              elevation="0"
              outlined
              v-for="list in listInfluencers"
              :key="list.id"
            >
              <!-- <v-img class="pointer" @click="goToDetail(list.id)" height="150" :src="list.photo" /> -->
              <v-img
                class="pointer" @click="goToDetail(list.id)" height="150"
                :src="list.photo"
              >
                <v-app-bar
                  flat
                  color="rgba(0, 0, 0, 0)"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="list.isPriority === 1"
                    elevation="0"
                    color="white"
                    fab
                    x-small
                    class="recommended"
                    aria-label="recomended"
                  >
                    <v-icon class="primary--text">
                      {{ icons.mdiLightningBolt }}
                    </v-icon>
                  </v-btn>
                </v-app-bar>
              </v-img>
              <v-card-text class="pa-2 pb-0">
                <p class="left-text black--text text-14 font-weight-bold mb-1">******** <i style="float:right" class="fas fa-eye-slash pt-0 mt-0 text-12"></i></p>
                <!-- TIKTOK -->
                <div v-if="type === 'tiktok'">
                  <v-btn @click="goToLogin()" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-eye mr-2"></i> Unblock</v-btn>
                  <p class="mb-0 text-12"><i class="fab fa-tiktok pr-1"></i>********</p>
                  <p class="text-12 mb-0"><i class="far fa-user-circle pr-1"></i>{{list.follower_tiktok | nFormatter}} Followers</p>
                </div>
                <!-- INSTAGRAM -->
                <div v-else>
                  <v-btn @click="goToLogin()" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-eye mr-2"></i> Unblock</v-btn>
                  <p class="mb-0 text-12"><i class="fab fa-instagram pr-1"></i>********</p>
                  <p class="text-12 mb-0"><i class="far fa-user-circle pr-1"></i>{{list.follower_ig | nFormatter}} Followers</p>
                </div>
              </v-card-text>
            </v-card>
          </div>

          <div class="row pa-3 mt-3 d-flex justify-center">
            <v-progress-circular
              v-if="isLoadingMore"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <v-btn v-else @click="loadMore()" outlined color="primary" rounded class="text-capitalize">Load More</v-btn>
          </div>
        </div>
      </div>
    </div>

    <!-- DESKTOP -->
    <div v-else class="mt-3">
        <p class="title black--text font-weight-bold">Rekomendasi Influencer</p>
      <div v-if="isLoading" class="row pl-3 pr-3 d-flex justify-space-between pt-3">
        <v-card
          width="190px"
          height="270px"
          class="round mb-3"
          elevation="0"
          outlined
          v-for="n in 6"
              :key="n"
        >
          <v-skeleton-loader
            type="card"
          ></v-skeleton-loader>
        </v-card>
      </div>
      <div v-else>
        <!-- KETIKA LOGIN -->
        <div v-if="isLogin" no-gutters class="row pl-3 pr-3 d-flex justify-space-between pt-3">
          <v-card
            width="190"
            height="270px"
            class="round mb-3"
            elevation="0"
            outlined
            v-for="list in listInfluencers" :key="list.id"
          >
            <v-img
              class="pointer" @click="goToDetail(list.id)" height="150"
              :src="list.photo"
            >
              <v-app-bar
                flat
                color="rgba(0, 0, 0, 0)"
              >
                <v-spacer></v-spacer>
                <v-btn
                  v-if="list.isPriority === 1"
                  elevation="0"
                  color="white"
                  fab
                  x-small
                  class="recommended"
                  aria-label="recomended"
                >
                  <v-icon class="primary--text">
                    {{ icons.mdiLightningBolt }}
                  </v-icon>
                </v-btn>
              </v-app-bar>
            </v-img>
            <v-card-text class="pa-2 pb-0 black--text">
              <p v-if="list.name.length < 16" class="left-text black--text text-14 font-weight-bold mb-1">{{ list.name }}</p>
              <p v-else class="left-text black--text text-14 font-weight-bold mb-1">{{list.name.substring(0,16)+".."}}</p>
              <v-btn @click="book(list.instagram)" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-shopping-cart mr-2"></i>Book Now</v-btn>
              <div v-if="type === 'tiktok'">
                <p v-if="list.tiktok.length < 20" class="mb-0 text-12"><i class="fab fa-tiktok pr-1"></i>{{ list.tiktok }}</p>
                <p v-else class="mb-0 text-12"><i class="fab fa-tiktok pr-1"></i>{{list.tiktok.substring(0,20)+".."}}</p>
                <p class="text-12 mb-0"><i class="far fa-user-circle pr-1"></i>{{list.follower_tiktok | nFormatter}} Followers</p>
              </div>
              <div v-else>
                <p v-if="list.instagram.length < 20" class="mb-0 text-12"><i class="fab fa-instagram pr-1"></i>{{ list.instagram }}</p>
                <p v-else class="mb-0 text-12"><i class="fab fa-instagram pr-1"></i>{{list.instagram.substring(0,20)+".."}}</p>
                <p class="text-12 mb-0"><i class="far fa-user-circle pr-1"></i>{{list.follower_ig | nFormatter}} Followers </p>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <!-- KETIKA BELUM LOGIN -->
        <div v-else class="row pl-3 pr-3 d-flex justify-space-between pt-3">
          <v-card
            width="190px"
            height="270px"
            class="round mb-3"
            elevation="0"
            outlined
            v-for="list in listInfluencers" :key="list.id"
          >
            <v-img
              class="pointer" @click="goToDetail(list.id)" height="150"
              :src="list.photo"
            >
              <v-app-bar
                flat
                color="rgba(0, 0, 0, 0)"
              >
                <v-spacer></v-spacer>
                <v-btn
                  v-if="list.isPriority === 1"
                  elevation="0"
                  color="white"
                  fab
                  x-small
                  class="recommended"
                  aria-label="recomended"
                >
                  <v-icon class="primary--text">
                    {{ icons.mdiLightningBolt }}
                  </v-icon>
                </v-btn>
              </v-app-bar>
            </v-img>
            <v-card-text class="pa-2 pb-0 black--text">
              <p class="left-text black--text text-14 font-weight-bold mb-1">******** <i style="float:right" class="fas fa-eye-slash pt-0 mt-0 text-12"></i></p>
              <v-btn @click="goToLogin()" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-eye mr-2"></i> Unblock</v-btn>
              <div v-if="type === 'tiktok'">
                <p class="mb-0 text-12"><i class="fab fa-tiktok pr-1"></i>********</p>
                <p class="text-12 mb-0"><i class="far fa-user-circle pr-1"></i>{{list.follower_tiktok | nFormatter}} Followers</p>
              </div>
              <div v-else>
                <p class="mb-0 text-12"><i class="fab fa-instagram pr-1"></i>********</p>
                <p class="text-12 mb-0"><i class="far fa-user-circle pr-1"></i>{{list.follower_ig | nFormatter}} Followers</p>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <div class="row pa-3 mt-3 d-flex justify-center">
        <v-progress-circular
          v-if="isLoadingMore"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-btn v-else @click="loadMore()" outlined color="primary" rounded class="text-capitalize">Load More</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mdiLightningBoltOutline, mdiLightningBolt } from '@mdi/js'
export default {
  props:['data'],
  setup() {
    return {
      isLoading:true,
      isLoadingMore:false,
      isLogin:true,
      reload:true,
      page:'',
      offset:1,
      isMobileWidth:0,
      type:'z',
      listInfluencers:[],
      icons: {
        mdiLightningBoltOutline,
        mdiLightningBolt
      }
    }
  },
  mounted(){
    this.page = this.$route.query.item
    window.scrollTo(0,0);
    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
    if(!localStorage.getItem('token')){
      this.isLogin = false
      this.loadInfluencersNot()
    } else {
      this.isLogin=true
      this.loadInfluencers()
    }
  },
  methods:{
    encryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg='
      return this.$CryptoJS.AES.encrypt(val.toString(), secretKey).toString();
    },
    loadInfluencers(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      let endPoint
      if(this.type === 'tiktok'){
        endPoint = 'influencers/getTiktokHomeLogin/'+this.offset
      } else {
        endPoint = 'influencers/getInfluencersHomeLogin/'+this.type+'/'+this.offset
      }
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + endPoint, {
          headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response){
            let data = response.data;
            let num = 1
            for(let a=0; a < data.length; a++){
              data[a].num = num++
              data[a].city_name === null ? data[a].city_name = data[a].city:''
              axios({
                url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
                headers: {
                  'Authorization': 'Bearer '+ token
                },
                method: 'GET',
                responseType: 'blob',
              }).then((res) => {
                const blob = new Blob([res.data], { type: "application/JPG" });
                data[a].photo = URL.createObjectURL(blob);
              });
            }
            this.listInfluencers = data
            setTimeout(() => {
              this.offset = 0
              this.isLoading = false
            }, 2000);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    loadInfluencersNot(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      let endPoint
      if(this.type === 'tiktok'){
        endPoint = 'influencers/getTiktokHome/'+this.offset
      } else {
        endPoint = 'influencers/getInfluencersHome/'+this.type+'/'+this.offset
      }
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + endPoint, {
          headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response){
            let data = response.data;
            for(let a=0; a < data.length; a++){
              axios({
                url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
                headers: {
                  'Authorization': 'Bearer '+ token
                },
                method: 'GET',
                responseType: 'blob',
              }).then((res) => {
                const blob = new Blob([res.data], { type: "application/JPG" });
                data[a].photo = URL.createObjectURL(blob);
              });
            }
            this.listInfluencers = data
            setTimeout(() => {
              this.offset = 0
              this.isLoading = false
            }, 2000);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    loadMore(){
      this.isLoadingMore = true
      let token = localStorage.getItem('token')
      this.offset = this.offset + 13
      let endPoint = ''
      if(token){
        if(this.type === 'tiktok'){
          endPoint = 'influencers/getTiktokHomeLogin/'+this.offset
        } else {
          endPoint = 'influencers/getInfluencersHomeLogin/'+this.type+'/'+this.offset
        }
      } else {
        if(this.type === 'tiktok'){
          endPoint = 'influencers/getTiktokHome/'+this.offset
        } else {
          endPoint = 'influencers/getInfluencersHome/'+this.type+'/'+this.offset
        }
      }
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + endPoint, {
          headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response){
            let data = response.data;
            for(let a=0; a < data.length; a++){
              axios({
                url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
                headers: {
                  'Authorization': 'Bearer '+ token
                },
                method: 'GET',
                responseType: 'blob',
              }).then((res) => {
                const blob = new Blob([res.data], { type: "application/JPG" });
                data[a].photo = URL.createObjectURL(blob);
              });
            }
            for(let a=0; a < data.length; a++){
              this.listInfluencers.push(data[a])
            }
            setTimeout(() => {
              this.isLoadingMore = false
            }, 2000);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    goToDetail(id){
      this.$router.push({name:'influencer-detail', query: {user: this.encryptData(id)}});
    },
    book(talent){
      window.open('https://wa.me/6285691701929?text=Halo Admin, Saya tertarik kepada talent *@'+talent+'* untuk bekerjasama. Mohon diinfokan rate cardnya ya. \n Terima kasih', '_blank');
    },
    goToLogin(){
      this.$router.push({name:'login'});
    },
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  },
  filters:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    nFormatter(num) {
      if (num >= 1000000000) {
          return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
      }
      if (num >= 1000000) {
          return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      }
      if (num >= 1000) {
          return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
      }
      return num;
    }
  }
}
</script>
